export default appId => {
  if (appId === 'ambition') return require('@/assets/images/logos/AmbitionLogoLarge.png')
  if (appId === 'azureTableStorage') return require('@/assets/images/logos/AzureTableStorageLogoLarge.png')
  if (appId === 'bing') return require('@/assets/images/logos/BingAdsLogoLarge.png')
  if (appId === 'facebook') return require('@/assets/images/logos/FacebookAdsLogoLarge.png')
  if (appId === 'googleAds') return require('@/assets/images/logos/GoogleAdsLogoLarge.png')
  if (appId === 'googleSheets') return require('@/assets/images/logos/GoogleSheetsLogoLarge.png')
  if (appId === 'gong') return require('@/assets/images/logos/GongLogoLarge.png')
  if (appId === 'hubspot') return require('@/assets/images/logos/HubspotLogoLarge.png')
  if (appId === 'lendioModelingService') return require('@/assets/images/logos/LendioLogoLarge.png')
  if (appId === 's3') return require('@/assets/images/logos/S3LogoLarge.svg')
  if (appId === 'tableau') return require('@/assets/images/logos/TableauLogoLarge.png')
  if (appId === 'workday') return require('@/assets/images/logos/WorkdayLogoLarge.png')
  if (appId === 'yahoo') return require('@/assets/images/logos/YahooAdsLogoLarge.png')
  if (appId === 'zendesk') return require('@/assets/images/logos/ZendeskLogoLarge.png')

  return require('@/assets/images/logos/SnowFlowLogo_Large.png')
}
